<template>
	<div class="page container">
		<div class="page__info">
			<div class="page__info--title">
				Setup profile
			</div>
		</div>

		<div data-element="main">
			<validation-observer
				data-element="form"
				tag="form"
				ref="form"
				@submit.prevent="submit"
			>
				<input-field
					name="First name"
					type="text"
					rules="required"
					v-model="profile.firstName"
				/>
				<input-field
					name="Last name"
					type="text"
					rules="required"
					v-model="profile.lastName"
				/>
				<select-field
					name="Your experience level"
					rules="required"
					:options="experienceLevelOptions.slice(0,8)"
					v-model="profile.experienceLevel"
				/>
				<select-field
					name="Your preferred bidding system"
					rules="required"
					:options="biddingSystemOptions"
					v-model="profile.biddingSystem"
				/>
				<actions
					:actions="actions"
					:isWaiting="isWaiting"
					@actionClick="onActionClick"
				/>
			</validation-observer>
		</div>
	</div>
</template>

<script>
	import InputField            from '@/components/forms/InputField';
	import SelectField           from '@/components/forms/SelectField';
	import Actions               from '@/components/ui/Actions';
	import actionClick           from '@/mixins/actionClick';
	import invalidFormMessage    from '@/mixins/invalidFormMessage';
	import api                   from '@/services/api';
	import { experienceLevels }  from '@/consts';
	import { biddingSystems }    from '@/consts';

	export default {
		metaInfo: {
			title: 'Setup profile'
		},
		layout: 'v2default',
		components: {
			InputField,
			SelectField,
			Actions
		},
		mixins: [actionClick, invalidFormMessage],
		data: () => ({
			isWaiting: undefined,
			experienceLevelOptions: experienceLevels,
			biddingSystemOptions: biddingSystems,
			profile: {
				firstName:        '',
				lastName:         '',
				experienceLevel:  '',
				biddingSystem:    '',
				timezone:         undefined
			},
			actions: {
				primary: [
					{
						text: 'Save',
						type: 'submit',
						btnId: 'submit'
					}
				],
				secondary: [
					{
						text: 'Cancel',
						actionId: 'cancel'
					}
				]
			}
		}),
		computed: {
			getBreadcrumbs () {
				return [
					{
						path: '/setup-profile',
						text: 'Setup profile'
					}
				];
			}
		},
		created () {
			this.$store.commit('ui/setBreadcrumbs', {
				breadcrumbs: this.getBreadcrumbs
			});
			this.$store.commit('ui/setLoadingIsHidden');
		},
		methods: {
			async cancel () {
				this.$store.commit('auth/logOut');
				this.$store.commit('auth/clearProfile');
				await this.$router.push('/login');
			},
			async submit () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.showInvalidFormMessage();
					return;
				}
				this.isWaiting = 'submit';
				const profile = await api.auth.updateCurrentUserProfile({
					profile: {
						...this.profile,
						timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'Europe/London'
					}
				});
				if (!profile) {
					this.isWaiting = undefined;
					return false;
				}
				this.isWaiting = undefined;
				this.$store.commit('auth/setProfile', {
					profile
				});
				await this.$router.push('/browse-courses');
				this.$store.commit('ui/showNotification', {
					notification: 'Your profile has been updated'
				});
			}
		}
	};

</script>
